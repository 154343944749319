<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_research.activity_plan') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('research_manage.thematic_area')"
                label-for="thematic_area_id"
                >
                <b-form-select
                    plain
                    v-model="search.thematic_area_id"
                    :options="thematicAreaList"
                    id="thematic_area_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('research_manage.project_id')"
                label-for="project_status"
                >
                <b-form-input
                    plain
                    v-model="search.project_id"
                    id="project_status"
                    >
                </b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('external_research.activity_plan') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button type="button" variant="primary" size="sm" @click="redirectToActivities(data.item.id)">
                        {{ $t('external_research.activity_update') }}
                      </b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalActivityListApi } from '../../../api/routes'
import RouteBasedList from '@/mixins/route-base-list'

export default {
  mixins: [RouteBasedList],
  components: {
  },
  data () {
    return {
      rows: [],
      item: '',
      search: {
        thematic_area_id: 0,
        project_id: ''
      }
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    thematicAreaList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.filter(item => item.status === 1)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('external_research.project_id'), class: 'text-left' },
          { label: this.$t('external_research.project_title'), class: 'text-left' },
          { label: this.$t('external_research.thematic_area'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'proposal_auto_id' },
          { key: 'project_title_bn' },
          { key: 'thematic_area_name_bn' },
          { key: 'activity_status_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'proposal_auto_id' },
          { key: 'project_title' },
          { key: 'thematic_area_name' },
          { key: 'activity_status_en' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    redirectToActivities (proposalId) {
      this.$router.push({ path: '/research-farmer/research-management/activity-plan-activities', query: { id: proposalId } })
    },
    searchData () {
      this.loadData()
    },
    loadData () {
      const params = Object.assign({}, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, proposalActivityListApi, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
        // Relational
        let activityStatusEn = ''
        let activityStatusBn = ''
        const newActivityList = item.activities.filter(activity => activity.activity_status === 0)
        if (newActivityList.length === item.activities.length) {
          activityStatusEn = 'Not Started Yet'
          activityStatusBn = 'এখনো শুরু হয়নি'
        } else {
            activityStatusEn = 'Ongoing'
            activityStatusBn = 'চলমান'
        }
        const thematicArea = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(el => el.value === item.invitation.thematic_area_id)
        // Evaluate
        const evaluationData = {
          activity_status_en: activityStatusEn,
          activity_status_bn: activityStatusBn,
          thematic_area_name: thematicArea === undefined ? null : thematicArea.text_en,
          thematic_area_name_bn: thematicArea === undefined ? null : thematicArea.text_bn
        }

        // Assign
        return Object.assign({}, item, evaluationData)
      })
      return listData
    }
  }
}
</script>
